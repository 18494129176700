/*eslint-disable*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import keys from "../../lib/config"
import axios from "axios";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);
let url = keys.Backend_url

export default function FooterPages(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const [allMedia,setAllMedia] =useState()

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const getData = () =>{
    axios.get( url + "/api/allsocialMedia").then((res)=>{
      if(res&&res.data&&res.data.status == true){
        setAllMedia(res.data.data)
      }
    })
  }
  
  useEffect(()=>{
    getData()
  },[])

  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_bottom_home">
          <div className="login_social_links">
            {/* <ul className="social_links" data-aos="fade-in" data-aos-duration="1000">
              <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-reddit-alien"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-youtube"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
            </ul>   */}
             {allMedia&&allMedia.length>0 &&allMedia.map((item)=>{
                // console.log(item.telegram,"itemmm")
                return(
                  <>
            <ul className="social_links" data-aos="fade-in" data-aos-duration="1000">
                  <li><a href={item.telegram} target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                 <li><a href={item.linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                 <li><a href={item.reddit} target="_blank"><i className="fab fa-reddit-alien"></i></a></li>
              <li><a href={item.youtube} target="_blank"><i className="fab fa-youtube"></i></a></li>
              <li><a href={item.instagram} target="_blank"><i className="fab fa-instagram"></i></a></li>
            </ul>  

                  </>
                )
              })}
            <div className="copyright_txt mt-3">Copyright © {" "}{1900 + new Date().getYear()}{" "}<Link to="/" className={aClasses}>OrbitX</Link>.
              All Rights Reserved.
            </div>          
          </div>
        </div>
      </div>
    </footer>
  );
}

FooterPages.propTypes = {
  whiteFont: PropTypes.bool
};
