/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import copy from "copy-to-clipboard";
import "@metamask/legacy-web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import { toastAlert } from "../../helper/toastAlert";
import { Link } from "react-scroll";
import Web3 from "web3";
import { Redirect } from "react-router-dom";
import Web3Modal, { local } from "web3modal";
import jwt_decode from "jwt-decode";
//abi file
import bnbAbi from "../../ABI/bnbAbi.json";
import icoAbi from "../../ABI/icoAbi.json";
import busdAbi from "../../ABI/busdabi.json";
import axios from "axios";
import keys from "../../lib/config";

const networkVersion = keys.networkVersion
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  let url = keys.Backend_url;
  let buyTokenUrl = keys.buyToken;
  const [accounts, setaccount] = React.useState();
  const [orbitTokenBalance, setorbitTokenBalance] = useState();

  const [BUSDBalance, setBUSDBalance] = useState();
  const [TokenBalance, setTokenBalance] = useState();
  const [BNBBalance, setBNBBalance] = useState();
  const [splitAddress, setsplitAddress] = useState();
  const [walletAddress, setwalletAddress] = useState();
  const [tokenValue, settokenValue] = useState();

  const history = useHistory();
  let  orbitxContract = keys.orbitxContract
  let busdcontract = keys.busdcontract
let icoContract = keys.icoContract;

  // let orbitxContract = "0x68fC0b4d7838Fa1ea42158C5f63445b2AED6E785"; //bnb
  // let busdcontract = "0x26d8b99a3b313e7831bbfaffa18d54cc3d8f92a0";
  const providerOptions = {
    connector: async (ProviderPackage, options) => {
      const provider = new ProviderPackage(options);
      await provider.enable();
      return provider;
    },
  };

  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });

  const web3Modal = new Web3Modal({
    //  network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 25) {
        $(".mainNavBar").addClass("fixedNavbar");
      } else {
        $(".mainNavBar").removeClass("fixedNavbar");
      }
    });
  }

  // useEffect(() => {
  //   var web3 = new Web3(window.ethereum);
  //   if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
  //   if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined ){
  //     connectClick();
  //   }
  //   window.ethereum.on('accountsChanged', function (accounts) {
  //     connectClick();
  //   })
  //    window.ethereum.on('networkChanged', function(networkId){
  //      connectClick(); x
  //    });
  //  }else{
  //   console.log("inin last")
  //    toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
  //  }
  // }, []);

  window.addEventListener("load", async (event) => {
    event.preventDefault();
    try {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
          localStorage.clear();
          toastAlert("warning", "Please connect new wallet", "excerciseerr");
          window.location.reload(true);
        });
      }
      // window.ethereum.on("networkChanged", function (networkId) {
      //   console.log(networkId,"networkId")
      //   localStorage.clear();
      //   toastAlert("warning", "Please connect new wallet", "excerciseerr");
      //   window.location.reload(true);
      // })
    } catch (err) {
      console.log(err);
    }
  });

  async function refreshClick() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const getaccounts = await web3.eth.getAccounts();
    const bnbContract = new web3.eth.Contract(bnbAbi, orbitxContract); //85
    const busdCon = new web3.eth.Contract(busdAbi, busdcontract); //2a0
    try {
      await web3.eth.net.getId().then((res) => {
        if (res == networkVersion) {
          let data = {
            walletAddress: getaccounts[0],
          };
          axios.post(`${url}/api/userWallet`, data).then((response) => {
            if (response && response.data && response.data.status == true) {
              // localStorage.setItem("admin_token", response.data.token);
              setaccount(getaccounts[0]);
              let tokenid = getaccounts[0]; 
              let viewId =
                getaccounts[0].slice(0, 5) +
                "..." +
                getaccounts[0].slice(38, 42);
              bnbContract.methods
                .balanceOf(getaccounts[0])
                .call()
                .then((bnbBal) => {
                  setTokenBalance(bnbBal / 1e18);
                  busdCon.methods
                    .balanceOf(getaccounts[0])
                    .call()
                    .then((busd) => {
                      setBUSDBalance(busd / 1e18);

                      web3.eth.getBalance(getaccounts[0]).then((res) => {
                        let bal = res / 1e18;
                        setBNBBalance(bal);

                        setsplitAddress(viewId);
                        setwalletAddress(getaccounts[0]);
                        localStorage.setItem("walletAddress", getaccounts[0]);
                      });
                    });
                });

              // window.location.href('/buy-token')
            }
          });
        } else {
          console.log("ininconncectclince===");
          toastAlert("error", "Select BSC Mainnet", "excerciseerr");
          setaccount("");
          return false;
        }
      });
    } catch (err) {
      console.log(err, "errrr");
    }
  }

  async function connectClick() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const getaccounts = await web3.eth.getAccounts();
    const bnbContract = new web3.eth.Contract(bnbAbi, orbitxContract); //85
    const busdCon = new web3.eth.Contract(busdAbi, busdcontract); //2a0
    try {
      await web3.eth.net.getId().then((res) => {
        if (res == networkVersion) {
          let data = {
            walletAddress: getaccounts[0],
          };
          axios.post(`${url}/api/userWallet`, data).then((response) => {
            if (response && response.data && response.data.status == true) {
              localStorage.setItem("admin_token", response.data.token);
              console.log(response, "response");
              setaccount(getaccounts[0]);
              let tokenid = getaccounts[0]; 
              let viewId =
                getaccounts[0].slice(0, 5) +
                "..." +
                getaccounts[0].slice(38, 42);
                localStorage.setItem("splitAddress", viewId);
              bnbContract.methods
                .balanceOf(getaccounts[0])
                .call()
                .then((bnbBal) => {
                    setTokenBalance(bnbBal / 1e18);

                  busdCon.methods
                    .balanceOf(getaccounts[0])
                    .call()
                    .then((busd) => {
                      setBUSDBalance(busd / 1e18);
                      web3.eth.getBalance(getaccounts[0]).then((res) => {
                        let bal = res / 1e18;
                        setBNBBalance(bal);
                        setsplitAddress(viewId);
                        setwalletAddress(getaccounts[0]);
                        localStorage.setItem("walletAddress", getaccounts[0]);
                        toastAlert(
                          "success",
                          "Wallet Connected",
                          "excerciseerr"
                        );
                        location.href = "/buy-token";
                      });
                    });
                });

              // window.location.href('/buy-token')
            }
          });
        } else {
          console.log("ininconncectclince===");
          toastAlert("error", "Select BSC Mainnet", "excerciseerr");
          setaccount("");
          return false;
        }
      });
    } catch (err) {
      console.log(err, "errrr");
    }
  }

  async function uncheck() {
    $(".toggler").prop("checked", false);
  }

  const connectWallet = () => {
    connectClick();
  };

  useEffect(() => {
    refreshClick()
  },[]) 

  const walletConnect = async () => {
    var provider = new WalletConnectProvider({
      rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/",
      },
    });
    // provider.on("connect", () => {});
    try {
      await provider.enable();
      let web3 = new Web3(provider);
      var network = await web3.eth.net
        .getId()
        .then((id) => {
          console.log(id, "iddddd");
          if (id == 56) {
            const bnbContract = new web3.eth.Contract(bnbAbi, orbitxContract); //85
            const busdCon = new web3.eth.Contract(busdAbi, busdcontract); //2a0
            web3.eth.getAccounts().then((walletAddress) => {
              let data = {
                walletAddress: walletAddress[0],
              };
              axios.post(`${url}/api/userWallet`, data).then((response) => {
                console.log(response, "resssss");
                if (response && response.data && response.data.status == true) {
                  localStorage.setItem("userWallet", response.data.token);
                  let viewId =
                    walletAddress[0].slice(0, 5) +
                    "..." +
                    walletAddress[0].slice(38, 42);
                localStorage.setItem("splitAddress", viewId);
                  setsplitAddress(viewId);
                  setwalletAddress(walletAddress[0]);
                  bnbContract.methods
                    .balanceOf(walletAddress[0])
                    .call()
                    .then((bnbBal) => {
                      console.log(bnbBal, "bnbBalbnbBal");
                        setTokenBalance(bnbBal / 1e18);
                      busdCon.methods
                        .balanceOf(walletAddress[0])
                        .call()
                        .then((busd) => {
                          console.log(busd, "busdbusd");
                          setBUSDBalance(busd / 1e18);
                          web3.eth.getBalance(walletAddress[0]).then((res) => {
                            console.log(res, "ressssss");
                            let bal = res / 1e18;
                            setBNBBalance(bal);
                            toastAlert(
                              "success", 
                              "Wallet Connected",
                              "excerciseerr"
                            );
                            // window.location.reload();
                          });
                        });
                    });
                }
              });
              //
            });
          } else {
            toastAlert("error", "Select BSC Mainnet", "excerciseerr");
          }
        })
        .catch((err) => {
          console.log(err, "errrr56666");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const quitWallet = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.push("/");
  };

  const busdBalance = async () => {
    await web3Modal.connect().then((res) => {
      const web3 = new Web3(res);
      var busdContract = new web3.eth.Contract(icoAbi, icoContract);
      web3.eth.getAccounts().then((address) => {
        busdContract.methods
          .getTokenprice()
          .call()
          .then((token) => {
            console.log(token / 1e6, "tokennnn");
            settokenValue(token);
          });
      });
    });
  };

  useEffect(() => {
    busdBalance();
  }, []);


  return (
    <div>
      <nav className="mainNavBar" data-aos="fade-down" data-aos-duration="1000">
        <div className="container">
          <div className="centerNav buytoken_nav">
            <div className="logo">
              <a href="/">
                <img
                  src={require("../../assets/images/logo.svg")}
                  className="img-fluid"
                  alt="Logo"
                />
              </a>
            </div>
            {!localStorage.getItem("walletAddress") ? (
              <>
                <button
                  className="connectButton"
                  data-toggle="modal"
                  data-target="#connect_wallet_modal"
                >
                  <span>Connect Wallet</span>
                </button>
              </>
            ) : (
              <>
                <div className="buy_nav_right">
                  {/* <h4>OrbitX ${tokenValue}</h4> */}
                  <h4>OrbitX $0.075</h4>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="connectButton dropdown-toggle"
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {/* <span> {splitAddress}</span> */}
                      <span> {localStorage.getItem("splitAddress")}</span>
                    </button>
                    <div
                      class="dropdown-menu walletDropMain"
                      aria-labelledby="navbarDropdown"
                    >
                      <p>
                        <label>BNB Balance</label>
                        <span>{BNBBalance || 0}</span>
                      </p>
                      <p>
                        <label>BUSD Balance</label>
                        <span>{BUSDBalance || 0}</span>
                      </p>
                      <p>
                        <label>OrbitX Token Balance</label>
                        <span>{TokenBalance || 0}</span>
                      </p>
                      <p>
                        <a class="logoutLink" href="#" onClick={quitWallet}>
                          Disconnect
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
      {/* Connect Wallet Modal */}
      <div
        className="modal fade"
        id="connect_wallet_modal"
        tabindex="-1"
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3 d-flex justify-content-center">
                  <button
                    type="button"
                    className="connect_wallet_button"
                    onClick={connectWallet}
                  >
                    <img
                      src={require("../../assets/images/metamask.png")}
                      height="128"
                      width="128"
                      className="mr-1 v-align-middle"
                    />
                    MetaMask
                  </button>
                  <button
                    type="button"
                    class="connect_wallet_button ml-2"
                    onClick={walletConnect}
                  >
                    <img
                      src={require("../../assets/images/walletconnect.png")}
                      height="128"
                      width="128"
                      class="mr-1 v-align-middle"
                    />
                    WalletConnect
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
