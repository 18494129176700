import React, { useState, useEffect } from "react";
import Select from "react-select";
// core components
import HeaderLinks from "components/Header/HeaderLinks2.js";
import FooterPages from "components/Footer/FooterPages.js";
import DataTable from "react-data-table-component";
//abi file
import bnbAbi from "../ABI/bnbAbi.json";
import icoAbi from "../ABI/icoAbi.json";
import busdAbi from "../ABI/busdabi.json";
import moment from "moment";

// config----
import keys from "../lib/config";

import Web3 from "web3";
import Web3Modal from "web3modal";
import { toastAlert } from "../helper/toastAlert";
import axios from "axios";

// backendUrl
const url = keys.Backend_url;

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const supplyoptions = [
  { value: "BNB", label: "BNB" },
  { value: "BUSD", label: "BUSD" },
];

const providerOptions = {
  connector: async (ProviderPackage, options) => {
    const provider = new ProviderPackage(options);
    await provider.enable();
    return provider;
  },
};
const columns = [
  {
    name: "Date",
    selector: "createdAt",
    sortable: false,
    cell: (record) => {
      return (
        <div>
          <p>{moment(record.createdAt).format("MMMM, Do YYYY, hh:mm A")}</p>
        </div>
      );
    },
  },
  {
    name: "Transaction Id",
    selector: "transactionHash",
    sortable: false,
    cell: (record) => {
      let hash = record.transactionHash;
      let hashid = hash.slice(0,10) + "..." + hash.slice(56,66)
      return (
        <div>
          <a
            href={`https://bscscan.com/tx/${record.transactionHash}`}
            target="_blank"    
          >
            {hashid}
          </a>
        </div>
      );
    },
  },
  {
    name: "Token Type",
    selector: "tokenType",
    sortable: false,
  },
  {
    name: "Amount",
    selector: "deposit",
    sortable: false,
  },
  {
    name: "Earned Token",
    selector: "tokenGet",
    sortable: false,
  },
];

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

export default function BuyToken(props) {
  const [tokentype, setTokenType] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [tokenGet, settokenGet] = useState();
  const [finalValue, setFinalValue] = useState();
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState();
  // let adminAddress = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a";

  let orbitxContract = keys.orbitxContract //bnb
  let busdcontract = keys.busdcontract
  let icoContract = keys.icoContract

  // let orbitxContract = "0x68fC0b4d7838Fa1ea42158C5f63445b2AED6E785"; //bnb
  // let busdcontract = "0x26d8b99a3b313e7831bbfaffa18d54cc3d8f92a0";
  // let icoContract = "0x6E530E345e80F9cADF130a1D609126ddE4ACfa34";
  // pp:0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "null" : null,
      };
    },
  };

  const getData = async () => {
    web3Modal.connect().then((res) => {
      const web3 = new Web3(res);
      const accounts = web3.eth.getAccounts().then((address) => {
        let userAddress = address[0]
        let reqdata = { walletAddress: userAddress };
        axios
          .post(`${url}/api/transactionHistory`, reqdata)
          .then((response) => {
            // console.log(response, "resss");
            if (response && response.data && response.data.status == true) {
              setAllUsers(response.data.data);
            }
          })
          .catch((err) => {
            console.log(err, "errrr");
          });
      })
    })
  };

  useEffect(() => {
    getData();
  }, []);

  const tokenType = async (e) => {
    setTokenType(e.value)
     setDepositAmount(depositAmount);

     amount2(depositAmount, e.value);
     
  };
  const amount = async (e) => {
    setTokenType(tokentype)
     setDepositAmount(e.target.value);
     amount2(e.target.value, tokentype);
  }

  const amount2 = (value, tokentype1) => {
    if(tokentype1 == "") {
      toastAlert("error", "Select token type", "excerciseerr");
      return false;
    }
    if(value > 0) {
    setDepositAmount(value);
    setTokenType(tokentype1);
    web3Modal.connect().then((res) => {
      const web3 = new Web3(res);
      const getaccounts = web3.eth.getAccounts().then((address) => {
        let walletAddress = address[0];
        if (parseFloat(value) > 0) {
          if (tokentype1 == "BNB") {
            console.log("usd");
            const web3 = new Web3(window.web3.currentProvider);
            const icocontract = new web3.eth.Contract(icoAbi, icoContract);
            var amt = web3.utils.toWei(value.toString());
            console.log(amt, "amttttBnb");
            try {
              icocontract.methods
                .getTokenfromBnb(amt.toString())
                .call()
                .then((res) => {
                  console.log(res, "bnbbbbresss");
                  let token = res / 10000000000;
                  console.log(token, "tokennnn");
                  setFinalValue(token);
                  console.log(res / 1e6, "BNB");
                });
            } catch (err) {
              console.log(err, "errrr");
            }
          } else {
            const busd = new web3.eth.Contract(icoAbi, icoContract);
            var amt = web3.utils.toWei(value.toString());
            console.log(amt, "amttBusd");
            try {
              busd.methods
                .getTokenfromBusd(amt.toString())
                .call()
                .then((res) => {
                  console.log(res, "resssBusd");
                  let token = res / 1e18;
                  setFinalValue(token);
                  console.log(res / 1e18, "BUSD");
                });
            } catch (err) {
              console.log(err, "err");
            }
          }
        }
      });
    });
  } else {
    console.log("err")
    // toastAlert("error", "Amount must be greater than zero", "excerciseerr");
  }
  };

  const willGet = (e) => {
    settokenGet(e.target.value);
  };

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }


  const buyToken = () => {
    var transId;
    let deposit = depositAmount;
    if(tokentype == "") {
      toastAlert("error", "Select token type", "excerciseerr");
      return false;
    }
    if(deposit == ''){
      toastAlert("error", "Select deposit amount", "excerciseerr");
      return false;
    }
    if(deposit > 0) {
    web3Modal
      .connect()
      .then((val) => {
        const web3 = new Web3(val);
        const accounts = web3.eth.getAccounts().then(async (acc) => {
          let walletAddress = acc[0];
          let storage = sessionStorage.getItem("walletAddress");
          const web3 = new Web3(window.web3.currentProvider);
          const icocontract = new web3.eth.Contract(icoAbi, icoContract); //a34
          const bnbContract = new web3.eth.Contract(bnbAbi, orbitxContract); //85
          const busdCon = new web3.eth.Contract(busdAbi, busdcontract); //2a0
          // console.log(tokentype, "typeee");
          // if (storage == null) {
          //   toastAlert("error", "Connect wallet", "excerciseerr");
          //   console.log("walletAddress");
          // }
          if (deposit == undefined) {
            console.log("emptyfield");
            toastAlert("error", "Input field can't be empty", "excerciseerr");
          } else if (tokentype == undefined) {
            toastAlert("error", "Input field can't be empty", "excerciseerr");
            console.log("tokentype empty");
          } else if (deposit == 0) {
            toastAlert("error", "Enter valid input", "excerciseerr");
            console.log("0");
          } else if (tokentype == "BNB") {
            setLoading(true);
            var amt = web3.utils.toWei(deposit.toString());
            console.log(amt, "amttttBnb");
            bnbContract.methods
              .balanceOf(walletAddress)
              .call()
              .then((bnbBal) => {
                console.log(bnbBal / 1e18,"1e18888 balll");
                console.log(bnbBal,"banb balll");
                console.log(amt,"amttttttt");
                if(parseFloat(bnbBal) < parseFloat(amt)) {
                  toastAlert("error", "Your balance is low", "excerciseerr");
                  setLoading(false);
                  return false;
                }
                console.log(bnbBal, "bnbBal");
              var amountBnb = convert(amt);
                var balance = bnbBal / 1e18;
                      setLoading(true);
                      console.log("bnb resultSuccess");
                      icocontract.methods
                        .depositBNB()
                        .send({
                          from: walletAddress,
                          value: amountBnb,
                        })
                        .on("transactionHash", (hash) => {
                          transId = hash;
                          console.log(hash, "hash-------------");
                        })
                        .then((bnbDepo) => {
                          console.log(bnbDepo, "bnbDepositt");
                          let data = {
                            tokenType: tokentype,
                            deposit: depositAmount,
                            tokenGet: finalValue,
                            walletAddress: walletAddress,
                            transactionHash: transId,
                          };
                          console.log(data, "dataaaa");
                          axios
                            .post(url + "/api/buyToken", data)
                            .then((response) => {
                              console.log(response, "ressssss");
                              if (
                                response &&
                                response.data &&
                                response.data.status == true
                              ) {
                                setLoading(false);
                                window.location.reload();
                                toastAlert(
                                  "success",
                                  "Deposit Completed",
                                  "excerciseerr"
                                );
                              }
                            });
                        })
                        .catch((err) => {
                          setLoading(false);
                          console.log(err, "errr");
                          toastAlert(
                            "error",
                            "Process rejected",
                            "excerciseerr"
                          );
                        });
                
              });
          } else {
            console.log("BUSD");
            setLoading(true);
            var amt = web3.utils.toWei(deposit.toString());
            var busdbal = await  busdCon.methods.balanceOf(walletAddress).call();
            if(busdbal < deposit*1e18) {
              toastAlert("error", "Your balance is low", "excerciseerr");
              setLoading(false);
              return false ;
            }
            var allowance = await  busdCon.methods
            .allowance(walletAddress,icoContract).call();
            var amountnew = convert(amt);
            if(allowance > amt) {
                  setLoading(true);
                  icocontract.methods
                    .depositBUSD(amountnew)
                    .send({
                      from: walletAddress,
                    })
                    .on("transactionHash", function (hash) {
                      transId = hash;
                      console.log(hash, "hash");
                    })
                    .then((busdDep) => {
                      console.log(busdDep, "busdDepbusdDep");
                      let data = {
                        tokenType: tokentype,
                        deposit: depositAmount,
                        tokenGet: finalValue,
                        walletAddress: walletAddress,
                        transactionHash: transId,
                      };
                      console.log(data, "dataaaBUSDddd");
                      axios
                        .post(url + "/api/buyToken", data)
                        .then((response) => {
                          console.log(response, "resssssssssssssBUSDD");
                          if (
                            response &&
                            response.data &&
                            response.data.status == true
                          ) {
                            setLoading(false);
                            window.location.reload();
                            toastAlert(
                              "success",
                              "Deposit Completed",
                              "excerciseerr"
                            );
                          }
                        });
                   
              })
              .catch((err) => {
                setLoading(false);
                toastAlert("error", "Approve rejected", "excerciseerr");
                console.log(err, "errrrrrrrrrrrrrrrrrrrrrr");
              });
            } else {
              var approveValue = web3.utils.toWei((deposit * 100000).toString()); ;
              var amt = web3.utils.toWei(deposit.toString());
              var busdbal = await  busdCon.methods.balanceOf(walletAddress).call();
              if(busdbal < deposit*1e18) {
                toastAlert("error", "your balance is low", "excerciseerr");
                setLoading(false);
                return false ;
              }
              var approveValue2 = convert(approveValue)
            busdCon.methods
              .approve(icoContract, approveValue2)
              .send({ from: walletAddress })
              .then((result) => {
                console.log(result, "resultttBusd");
                toastAlert("success", "Approve Completed", "excerciseerr");
                if (result && result.status == true) {
                  console.log("Busd Result success");
                  setLoading(true);
            var amountBusd = convert(amt);
                  icocontract.methods
                    .depositBUSD(amountBusd)
                    .send({
                      from: walletAddress,
                    })
                    .on("transactionHash", function (hash) {
                      transId = hash;
                      console.log(hash, "hash");
                    })
                    .then((busdDep) => {
                      console.log(busdDep, "busdDepbusdDep");
                      let data = {
                        tokenType: tokentype,
                        deposit: depositAmount,
                        tokenGet: finalValue,
                        walletAddress: walletAddress,
                        transactionHash: transId,
                      };
                      console.log(data, "dataaaBUSDddd");
                      axios
                        .post(url + "/api/buyToken", data)
                        .then((response) => {
                          console.log(response, "resssssssssssssBUSDD");
                          if (
                            response &&
                            response.data &&
                            response.data.status == true
                          ) {
                            setLoading(false);
                            window.location.reload();
                            toastAlert(
                              "success",
                              "Deposit Completed",
                              "excerciseerr"
                            );
                          }
                        });
                    })
                    .catch((err) => {
                      setLoading(false);
                      toastAlert("error", "Process rejected", "excerciseerr");
                      console.log(err, "errr");
                    });
                }
              })
              .catch((err) => {
                setLoading(false);
                toastAlert("error", "Approve rejected", "excerciseerr");
                console.log(err, "errrrrrrrrrrrrrrrrrrrrrr");
              });
            }
            
          }
        });
      })
      .catch((err) => {
        console.log(err, "errrrrrressss");
      });
    } else {
      toastAlert("error", "Amount must be greater than zero", "excerciseerr");
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <HeaderLinks />
      <div className="main">
        <section className="buyTokenBg">
          <div class="ag-fireflies_box">
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
          </div>
          <div className="container">
            <div
              className="buyTokenTransBg boxSpace"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="buyTokenHead">
                <h3>BUY TOKEN</h3>
              </div>
              <div className="buyTokenForm">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Select Token Type *</label>
                      <Select
                        options={supplyoptions}
                        styles={colourStyles}
                        onChange={(e)=>tokenType(e)}
                        className="form-control customSelect"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Enter Deposit Amount *</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={amount}
                        // value= "1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>You Will Get</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={willGet}
                        value={finalValue}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group text-center mt-3">
                      <button className="pinkButton" onClick={buyToken}>
                        <span>
                          {loading && (
                            <i class="fas fa-spinner fa-spin ml-2"></i>
                          )}
                          Buy Token
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="buyTokenTransBg"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="customTable">
                <DataTable columns={columns} data={allUsers} noHeader />
              </div>
            </div>
          </div>
          ``
        </section>
        <div className="bottomSectionGroup pagesFooter">
          <FooterPages />
        </div>
      </div>
    </div>
  );
}

// if (deposit == undefined) {
//   console.log("emptyfield");
//   toastAlert("error", "Input field can't be empty", "excerciseerr");
// } else if (storage == null) {
//   toastAlert("error", "Connect wallet", "excerciseerr");
//   console.log("walletAddress");
// }
