/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import keys from "../../lib/config";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";
import axios from "axios";

const useStyles = makeStyles(styles);
let url = keys.Backend_url;
export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const [allMedia, setAllMedia] = useState();
  const [targetValue, setTargetValue] = useState();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const getData = () => {
    axios.get(url + "/api/allsocialMedia").then((res) => {
      if (res && res.data && res.data.status == true) {
        setAllMedia(res.data.data);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.value)
    setTargetValue(event.target.value);
  };

  const subscribe = () => {
    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
    if(targetValue == undefined) {
      toast.error("Enter your email address");
    }else if(!validateEmail(targetValue)){
      toast.error("Enter Valid Email");
    } else {
      let data = {
        email: targetValue,
      };
      axios.post(`${url}/api/subscribe`, data).then((response) => {
        console.log(response, "ressssss");
        if (response && response.data && response.data.status == true) {
          toast.success(response.data.message);
          window.location.reload();
        } else {
          toast.error(response && response.data && response.data.error.email);
        }
      });
    }
  };

  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_bottom_home">
          <div className="login_social_links">
            <h1
              className="main_title main_title_center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Join our Newsletter
            </h1>
            <p
              className="titleText"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Subscribe to be informed about token sale in advance
            </p>
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <input
                type="text"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <p
              className="privacyText"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              By clicking "SUBSCRIBE" you agree to our Terms 
              {/* and that you have
              read our */}
               {/* <a href="#">Privacy Policy.</a> */}
            </p>
            <div className="form-group mt-3">
              <button
                className="pinkButton"
                data-aos="fade-up"
                data-aos-duration="1000"
                onClick={() => subscribe()}
              >
                <span>Subscribe</span>
              </button>
              <ToastContainer />
            </div>
            {/* <ul className="social_links" data-aos="fade-in" data-aos-duration="1000"> */}
            {allMedia &&
              allMedia.length > 0 &&
              allMedia.map((item) => {
                // console.log(item.telegram,"itemmm")
                return (
                  <>
                    <ul
                      className="social_links"
                      data-aos="fade-in"
                      data-aos-duration="1000"
                    >
                      <li>
                        <a href={item.telegram} target="_blank">
                          <i className="fab fa-telegram-plane"></i>
                        </a>
                      </li>
                      <li>
                        <a href={item.linkedin} target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href={item.twitter} target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href={item.reddit} target="_blank">
                          <i className="fab fa-reddit-alien"></i>
                        </a>
                      </li>
                      <li>
                        <a href={item.youtube} target="_blank">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href={item.instagram} target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </>
                );
              })}
            {/* <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-reddit-alien"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-youtube"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
            </ul>   */}
            <div className="copyright_txt mt-3">
              Copyright © {1900 + new Date().getYear()}{" "}
              <Link to="/" className={aClasses}>
                OrbitX{" "}
              </Link>
              . All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
