let NODE_ENV = 'production';
// let NODE_ENV = 'local';

let Front_URL = '';
let Backend_url = ''
let buyToken = ''
let decimalvalues = 1000000000000000000;
let toFixed = 5;

// testnet
let adminAddress = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a"
// let orbitxContract = "0x68fC0b4d7838Fa1ea42158C5f63445b2AED6E785" //bnb
// let busdcontract = "0x26d8b99a3b313e7831bbfaffa18d54cc3d8f92a0"
// let icoContract = "0x0c86A7e7068DC102d6EDEb966105fd17Bc7D65A6"

// livenet
let icoContract = "0x85D8CBAB1DC439d1b35fb43486763206e4e3ad65"
let busdcontract =  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
let orbitxContract = "0xf545b9800eb2B3e82E5BB92D3D68e270E18DE807"

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

// let networkVersion = '97' //bnb testnet
let networkVersion = "56" //bnbMainnet

if (NODE_ENV === "production") {
    // Front_URL = 'https://orbit.wealwin.com';
    // Backend_url = 'https://orbitapi.wealwin.com';

    Front_URL= "https://orbitxtoken.com";
    Backend_url= "https://api.orbitxtoken.com";
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://orbit.wealwin.com';
    Backend_url = 'https://orbitapi.wealwin.com';
}
else {
    Front_URL = 'http://localhost:3000';
    Backend_url = 'http://localhost:2053';
}

let key = {
    Front_URL: Front_URL,
    Backend_url:Backend_url,
    decimalvalues:decimalvalues,
    toFixed:toFixed,
    networkVersion: networkVersion,
    toasterOption: toasterOption,
    adminAddress: adminAddress,
    icoContract: icoContract,
    orbitxContract: orbitxContract,
    busdcontract: busdcontract,
};

export default key;


// let chocoacontract = "0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77"
// let busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F"
// let charlieicocontract = "0xCA2f5c288d522A1239E42727343ce893e1c15d3C"
