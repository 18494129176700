import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import HeaderLinks from "components/Header/HeaderLinks.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
} from "@material-ui/core";
import axios from "axios";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useHistory } from "react-router-dom";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/charlieicoabi.json";
import icoAbi from "../ABI/icoAbi.json";
import BUSD from "../ABI/busdabi.json";
const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;
const Backend_url = config.Backend_url;
let icoContract = config.icoContract;

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">
      <ExpandLess />
    </div>
    <div className="plus">
      <ExpandMore />
    </div>
  </div>
))`
  & > .plus {
    display: block;
    color: #fd7e14;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #fff;
    font-size: 32px;
    transform: rotate(180deg);
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const providerOptions = {
  connector: async (ProviderPackage, options) => {
    const provider = new ProviderPackage(options);
    await provider.enable();
    return provider;
  },
};

const provider = new WalletConnectProvider({
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    56: "https://bsc-dataseed.binance.org/",
  },
});

const web3Modal = new Web3Modal({
  //  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

const useStyles = makeStyles(styles);
let slider1;
let slider2;
export default function LandingPage(props) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [currAddress, setcurrentAddress] = React.useState("");
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [charlievalue, setcharlievalue] = React.useState(0);
  const [coin, setcoin] = React.useState("BUSD");
  const [charlieusd, setcharlieusd] = React.useState(0);
  const [show, setshow] = React.useState(false);
  const [userbnbbal, setuserbnbbal] = React.useState(false);
  const [userbusdbal, setuserbusdbal] = React.useState(false);
  const [progress, setprogress] = React.useState(false);
  const [allFaqData, setallFaqData] = useState();
  const [tokenValue, settokenValue] = useState();
  const history = useHistory();
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [navData, setNavData] = useState({
    nav1: null,
    nav2: null,
  });

  const getData = () => {
    axios.get(Backend_url + "/api/allFaqData").then((response) => {
      if (response && response.data && response.data.status == true) {
        // console.log(response.data.data,'allll')
        setallFaqData(response.data.data);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getcoinvalue();
    setNavData({
      nav1: slider1,
      nav2: slider2,
    });
    if (
      typeof localStorage.getItem("account") !== "undefined" &&
      localStorage.getItem("account") !== "" &&
      localStorage.getItem("account") !== null &&
      localStorage.getItem("account") !== undefined
    ) {
      var currAddress = localStorage.getItem("account");
      setcurrentAddress(currAddress);
      if (
        typeof window.web3 != "undefined" &&
        window.web3.currentProvider != "undefined"
      ) {
        if (window.web3.currentProvider.networkVersion != 97) {
          setcurrentAddress("");
          toastAlert("error", "Connect BSC Testnet", "excerciseerr");
        } else {
          getbalance(currAddress);
        }
      } else {
        toastAlert("error", "Connect BSC Testnet", "excerciseerr");
      }
    }
  }, []);
  const classes = useStyles();

  const slickSettingsVerticalNav = {
    arrows: true,
    vertical: true,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    verticalSwiping: true,
    ref: (slider) => (slider2 = slider),
    asNavFor: navData.nav1,
    // asNavFor: this.state.nav2,
    // ref: slider => (this.slider1 = slider),
  };

  const slickSettingsVerticalMain = {
    arrows: false,
    slidesToShow: 1,
    asNavFor: navData.nav2,
    ref: (slider) => (slider1 = slider),
    // asNavFor: this.state.nav1,
    // ref: slider => (this.slider2 = slider),
  };

  // Banner Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Mins</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Secs</span>
        </span>
      </div>
    );
  };

  const { ...rest } = props;

  var team_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  var roadmap_settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  /*------------developer code starts---------------------------------------*/

  async function alertconnect() {
    toastAlert("error", "Connect Wallet", "excerciseerr");
  }

  async function checkchange(termsandcon) {
    setaccept(termsandcon);
  }

  async function charliechange(value) {
    setcharlievalue(value);
    console.log(value, "========valvalval1char");
    if (
      value != "" &&
      value != undefined &&
      value != null &&
      parseFloat(value) > 0
    ) {
      if (coin == "BUSD") {
        var usd2 = parseFloat(value) / parseFloat(charlieusd);
        setcoinvalue(usd2.toFixed(5));
      }
    } else {
      setcoinvalue(0);
    }
  }

  async function inputchange(value) {
    setcoinvalue(value);
    console.log(value, "========valvalval1bnb");
    console.log(coin, "====================================coin");
    if (
      value != "" &&
      value != undefined &&
      value != null &&
      parseFloat(value) > 0
    ) {
      if (coin == "BUSD") {
        var trxtousdt = parseFloat(value) * parseFloat(charlieusd);
        setcharlievalue(trxtousdt.toFixed(5));
      }
    } else {
      setcharlievalue(0);
    }
  }

  async function selectchange(e) {
    console.log(e.target.value);
    setcoin(e.target.value);
  }

  async function getcoinvalue() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          const web3 = new Web3(window.web3.currentProvider);
          if (window.web3.currentProvider.isMetaMask === true) {
            var charlieContract = new web3.eth.Contract(ABI, contractAddr);
            var tokenvalue = await charlieContract.methods
              .getTokenprice()
              .call();
            console.log(tokenvalue, "========tokentoken");
            var tokenusd = parseFloat(tokenvalue) / 1000000;
            setcharlieusd(tokenusd);
          }
        } else {
          console.log("error", "Please Add Metamask External", "excerciseerr");
        }
      } catch (err) {
        console.log("error", "Please Add Metamask External", "excerciseerr");
      }
    } else {
      console.log("error", "Please Add Metamask External", "excerciseerr");
    }
  }

  async function getbalance(add) {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(add).call();
    console.log("tokenDethBln", busdBln);
    var value = parseFloat(busdBln) / 1000000000000000000;
    console.log("dethbalance:", busdBln);
    setuserbusdbal(value);
  }

  async function buyfunction() {
    setprogress(true);
    if (
      typeof localStorage.getItem("account") !== "undefined" &&
      localStorage.getItem("account") !== "" &&
      localStorage.getItem("account") !== null &&
      localStorage.getItem("account") !== undefined
    ) {
      var currAddress = localStorage.getItem("account");
      if (
        typeof window.web3 != "undefined" &&
        window.web3.currentProvider != "undefined"
      ) {
        if (window.web3.currentProvider.networkVersion != 97) {
          toastAlert("error", "Connect BSC Testnet", "excerciseerr");
          setprogress(false);
        } else {
          const provider = await web3Modal.connect();
          const web3 = new Web3(provider);
          if (
            coinvalue != null &&
            coinvalue != undefined &&
            parseFloat(coinvalue) > 0 &&
            coinvalue != ""
          ) {
            if (
              charlievalue != null &&
              charlievalue != undefined &&
              parseFloat(charlievalue) > 0 &&
              charlievalue != ""
            ) {
              if (coin == "BUSD") {
                if (parseFloat(coinvalue) < parseFloat(userbusdbal)) {
                  if (accept == true) {
                    var contract = new web3.eth.Contract(ABI, contractAddr);
                    var budcontrct = new web3.eth.Contract(
                      BUSD,
                      busdContractAddr
                    );
                    console.log(
                      contractAddr,
                      parseInt(
                        parseFloat(coinvalue) * config.decimalvalues
                      ).toString(),
                      "======input"
                    );
                    await budcontrct.methods
                      .approve(
                        contractAddr,
                        parseInt(
                          parseFloat(coinvalue) * config.decimalvalues
                        ).toString()
                      )
                      .send({ from: currAddress })
                      .then(async function (result1, error1) {
                        if (result1) {
                          await contract.methods
                            .deposit(
                              parseInt(
                                parseFloat(coinvalue) * config.decimalvalues
                              ).toString()
                            )
                            .send({ from: currAddress })
                            .then(async function (result, error) {
                              console.log(
                                result,
                                error,
                                "======================getgetget"
                              );
                              if (result) {
                                setprogress(false);
                                toastAlert(
                                  "success",
                                  "Successfully completed",
                                  "excerciseerr"
                                );
                                setcoinvalue(0);
                                setcharlievalue(0);
                              }
                            })
                            .catch(function (error) {
                              setprogress(false);
                              toastAlert(
                                "error",
                                "Error Occured Please Try Again Later",
                                "excerciseerr"
                              );
                              console.log(error, "======================error");
                            });
                        } else {
                          setprogress(false);
                          toastAlert(
                            "error",
                            "Error Occured Please Try Again Later",
                            "excerciseerr"
                          );
                        }
                      });
                  } else {
                    setprogress(false);
                    toastAlert(
                      "error",
                      "Please accept Terms and Condition",
                      "excerciseerr"
                    );
                  }
                } else {
                  setprogress(false);
                  toastAlert("error", "Insufficient Balance", "excerciseerr");
                }
              }
            } else {
              setprogress(false);
              toastAlert("error", "Invalid Input", "excerciseerr");
            }
          } else {
            setprogress(false);
            toastAlert("error", "Invalid Input", "excerciseerr");
          }
        }
      } else {
        setprogress(false);
        toastAlert("error", "Connect BSC Testnet", "excerciseerr");
      }
    } else {
      setprogress(false);
      toastAlert("error", "Connect BSC Testnet", "excerciseerr");
    }
  }

  const buyToken = () => {
    history.push("/buy-token");
  };

  const tokenomics = () => {
    history.push("/buy-token");
  };

  // const busdBalance = async () => {
  //   await web3Modal.connect().then((res) => {
  //     const web3 = new Web3(res);
  //     var busdContract = new web3.eth.Contract(icoAbi, icoContract);
  //     web3.eth.getAccounts().then((address) => {
  //       console.log(address, "address");
  //       busdContract.methods
  //         .getTokenprice()
  //         .call()
  //         .then((token) => {
  //           console.log(token / 1e6, "tokennnn");
  //           settokenValue(token);
  //         });
  //     });
  //   });
  // };

  // useEffect(() => {
  //   busdBalance();
  // }, []);

  return (
    <div>
      <HeaderLinks />
      <div className="main" id="tohome">
        <section className="mainBanner" name="home">
          <div className="container">
            <div className="bannerPadding">
              <ul class="star">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="homeBannerText"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h1>
                      Think bigger go beyond<span>Think bigger go beyond</span>
                    </h1>
                    <p>
                      The digital Token that aims to revolutionize the Crypto
                      world universally adoptable build on Binance chain
                      integrated with innovation and technology aims to
                      revolutionize the crypto world to a tremendous peak.
                    </p>
                    <div class="currentPrice"><h1>OrbitX $0.075<span>OrbitX $0.075</span></h1></div>
                    {/* <div class="currentPrice">
                      <h1>
                        OrbitX ${tokenValue}
                        <span>OrbitX ${tokenValue}</span>
                      </h1>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 d-none d-md-block">
                  <div
                    className="bannerImg rocket"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                  >
                    <img
                      src={require("../assets/images/rocketBanner.gif")}
                      className="img-fluid mainRocket"
                      alt="rocket"
                    />
                    <img
                      src={require("../assets/images/rocketBottom.png")}
                      className="img-fluid rocketBottom"
                      alt=""
                    />
                    <ul class="star">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
              {!sessionStorage.getItem("walletAddress") ? (
                <>
                  <div
                    className="bannerButton"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                  >
                    <div className="form-group text-center mt-4 bannerButtonBottom">
                      <button
                        className="pinkButton"
                        data-toggle="modal"
                        data-target="#connect_wallet_modal"
                      >
                        <span>Buy Token</span>
                      </button>
                      <a
                        className="text-white"
                        href="https://orbitxtoken.gitbook.io/orbitx/"
                        target="_blank"
                      >
                        <button className="blueButton rightButton">
                          <span>Pitch Deck</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="bannerButton"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                  >
                    <div className="form-group text-center mt-4 bannerButtonBottom">
                      <button className="pinkButton" onClick={buyToken}>
                        <span>Buy Token</span>
                      </button>
                      <button className="blueButton rightButton">
                        <span>White paper</span>
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="bannerButton" data-aos="fade-in" data-aos-duration="1000">
              <div className="form-group text-center mt-4 bannerButtonBottom">
                <button className="pinkButton" onClick={buyToken}><span>Buy Token</span></button>
                <button className="blueButton rightButton"><span>White paper</span></button>
              </div>
            </div> */}
            </div>
          </div>
        </section>
        <section className="aboutBg" name="about">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="aboutLeft"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                >
                  <h2>
                    ABOUT<span className="d-block">US</span>
                  </h2>
                  <img
                    src={require("../assets/images/aboutCurveImage.png")}
                    className="img-fluid"
                    alt="Logo"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="aboutContent"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <p>
                    {" "}
                    OrbitX would engrave the crypto world and thrive on being
                    the vital token for usage. We are in pursuit of building
                    potent OrbitX tokens to mesmerize the cryptopreneurs.{" "}
                  </p>
                  <p>
                    Pioneering the art of token creation providing OrbitX can be
                    the ideal source in the development of entities executing
                    quality service over the various token domains. With our
                    work turf spanning, we are able to use a variety of industry
                    knowledge to develop innovative solutions for a growing
                    digital audience.Take your business to the next level with
                    OrbitX.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="videoHome">
          <div className="container">
            <div
              className="titleBox"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-md-6">
                  <h2>OUR DISCOVER INFORMATION</h2>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <p className="mb-0">
                    The foundation was established by the skilled team members
                    of OrbitX in 2022 to provide support and governance for the
                    project. The Foundation has been invigorated by the members
                    of the original core team along with fresh faces and new
                    seasoned advisors ready to grow OrbitX for the decade ahead.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="landingVideo"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/R_9Rwc8ta0E"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>
        <section className="landingUsecase">
          <div class="ag-fireflies_box">
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
            <div class="ag-fireflies_item">
              <div class="ag-fireflies_inner"></div>
            </div>
          </div>
          <div className="container">
            <div
              className="useCaseTitle"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>
                <span className="d-block">Orbitx</span>Global Expansion
              </h2>
              {/* <p>OrbitX offers you a highly secured decentralized token exchange. We are committed to user protection with rigid protocols and industry-leading technical measures.</p> */}
            </div>
            <div className="usecaseCard">
              <div
                className="usecaseLeft usecaseList"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <ul>
                  <li>
                    <h3>OrbitXchange</h3>
                    <p>Get a big head start in the market with OrbitXchange</p>
                    {/* <h3>ICO</h3>
                      <p>We provide optimized, stable ICO which are premade to match your requirements.</p> */}
                    {/* <p>Get support and further assistance from us. </p> */}
                  </li>
                  <li>
                    <h3>DeFi</h3>
                    <p>
                      {" "}
                      Explore the DeFi exchange platform with the OrbitX token.
                    </p>
                  </li>
                  <li>
                    <h3>AI Trading Bot</h3>
                    <p>
                      AI Trading Bot enables trading of multiple
                      cryptocurrencies at a time.{" "}
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className="usecaseMidd"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img
                  src={require("../assets/images/rocketUsecase.png")}
                  className="img-fluid"
                  alt="Logo"
                />
              </div>
              <div
                className="usecaseRight usecaseList"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <ul>
                  <li>
                    <h3>NFT</h3>
                    <p>
                      OrbitX offers you with notable in-app features <br /> that
                      drive your business lucratively
                    </p>
                  </li>
                  <li>
                    <h3>Meta Game</h3>
                    <p>Our Meta game amuses users with the grand adventure.</p>
                  </li>
                  <li>
                    <h3>Digital Land Sale</h3>
                    <p>
                      You can procure, sell and explore the digital land plots
                      with our OrbitXtoken.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="halfGlobe">
              <img
                src={require("../assets/images/halfGlobe.png")}
                className="img-fluid"
                alt="globe"
              />
            </div>
          </div>
        </section>

        {/* Token Section */}

        <div className="TokenSectionBg" name="tokenomics">
          <div className="container">
            <h1
              className="main_title main_title_center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              TOKENOMICS
            </h1>
            {/* <p className="titleText" data-aos="fade-up" data-aos-duration="1000">OrbitX executes with top-notch demand and supply status which enthralls the attention of crypto users to dive deep into the sea of OrbitX tokens.</p> */}
            <div
              className="tokenDetails"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="tdCard">
                    <h3>Starting Time</h3>
                    <p>August 05,2022 7:00 AM</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tdCard">
                    <h3>Ending Time</h3>
                    <p>25 December,2022 8:00 PM</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tdCard">
                    <h3>Market Caps</h3>
                    <p>$150000000</p>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="tdCard">
                    <h3>Low - High 24h</h3>
                    <p>$ 6,455.83 - $ 7,071.42</p>
                  </div>
                </div> */}
                <div className="col-md-4 token">
                  <div className="tdCard">
                    <h3>Available Token</h3>
                    <p>$ 2 Billion</p>
                  </div>
                </div>
                <div className="col-md-4 token">
                  <div className="tdCard">
                    <h3>Acceptable Currency</h3>
                    <p>BNB,BUSD</p>
                  </div>
                </div>
              </div>
              <div className="form-group text-center mt-4">
                <button
                  className="pinkButton"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  onClick={tokenomics}
                >
                  <span>Buy Now</span>
                </button>
              </div>
            </div>
            <div
              className="tokenDistribution"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>Token Distribution</h2>
              <div className="row">
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>60%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Core Token Sale</h6>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>15%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Partners & Advisors</h6>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>10%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Project Team Share</h6>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>8%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Reserve Token</h6>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>4%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Bounties</h6>
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <div className="tokenDistributionCard">
                    <h3>3%</h3>
                    <img
                      src={require("../assets/images/tokenimg.png")}
                      className="img-fluid"
                      alt="Token"
                    />
                    <h6>Bonuses</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="roadmapHome" name="roadmap">
          <div className="container">
            <h1 className="main_title main_title_center">Roadmap</h1>
            {/* <p className="titleText">OrbitX executes with stunning peculiarities to entrace the requirements of the investors. Explore the endless possibilities with OrbitX. Some of the aspiring considerations for token experts</p> */}
            <div className="roadMapCard">
              <ul>
                {/* <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>Aug 5, 2022</h3>
                  <img src={require("../assets/images/rmICon1.png")} className="img-fluid" alt="Token" />
                  <div className="rmBox">
                    <h4>ICO</h4>
                    <p>Fill your crypto wallet with profitable OrbitXtoken.</p>
                  </div>
                </li> */}
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>Aug 30, 2022</h3>
                  <img
                    src={require("../assets/images/rmICon2.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>OrbitXchange</h4>
                    <p>Exchange your crypto instantly with OrbitX</p>
                  </div>
                </li>
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>22nd Oct 2022</h3>
                  <img
                    src={require("../assets/images/rmICon3.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>DeFi</h4>
                    <p>Experience a revolutionary decentralized fin-tech</p>
                  </div>
                </li>
              </ul>
              <ul>
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>Dec 25, 2022</h3>
                  <img
                    src={require("../assets/images/rmICon1.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>AI Trading Bot</h4>
                    <p>
                      We will enable auto trading with an AI-driven trading bot.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>Feb 14, 2023</h3>
                  <img
                    src={require("../assets/images/rmICon2.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>NFT</h4>
                    <p>Earn the crafted digital collectibles</p>
                  </div>
                </li>
              </ul>
              <ul>
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>May 9, 2023</h3>
                  <img
                    src={require("../assets/images/rmICon3.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>Meta Game</h4>
                    <p>Perceive the virtual grandeur</p>
                  </div>
                </li>
                <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>Aug 9, 2023</h3>
                  <img
                    src={require("../assets/images/rmICon1.png")}
                    className="img-fluid"
                    alt="Token"
                  />
                  <div className="rmBox">
                    <h4>Digital Land Sale</h4>
                    <p>Procure the digital virtual plots</p>
                  </div>
                </li>
                {/* <li data-aos="fade-in" data-aos-duration="1000">
                  <h3>JAN 2022</h3>
                  <img src={require("../assets/images/rmICon2.png")} className="img-fluid" alt="Token" />
                  <div className="rmBox">
                    <h4>Concept</h4>
                    <p>Concept Generation Team Assemble</p>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Team Section */}
        {/* Team Section */}
        <div className="team_section section" name="ourteam">
          <div className="container">
            <div className="text-center">
              <GridContainer data-aos="fade-up" data-aos-duration="1000">
                <GridItem sm={12} md={12} lg={12} className="m-auto">
                  <h1 className="main_title main_title_center">
                    Our Team
                    <small className="d-block mt-4 text-white">
                      (Coming Soon)
                    </small>
                  </h1>
                  {/* <p className="titleText">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas dui nunc, imperdiet at sollicitudin eu, accumsan id dolor. </p> */}
                </GridItem>
                {/* </GridContainer>
              <GridContainer data-aos="fade-up" data-aos-duration="1000">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_04.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Falk Maschitzki</h2>
                      <h4>Founder</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_01.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Melina Thomas</h2>
                      <h4>Co-Founder</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_02.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Elis Li</h2>
                      <h4>Chief Sales Officer</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_03.jpg")} className="img-fluid" alt="Team" />
                      
                    </div>
                    <div className="team_details">
                      <h2>Stuard Black</h2>
                      <h4>Senior Software Engineer</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_04.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Falk Maschitzki</h2>
                      <h4>Community Manager</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_01.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Melina Thomas</h2>
                      <h4>Advisor & Investor</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_02.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Elis Li</h2>
                      <h4>Chief Sales Officer</h4>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <div className="team_panel">
                    <div className="team_image">
                      <img src={require("../assets/images/team_03.jpg")} className="img-fluid" alt="Team" />
                    </div>
                    <div className="team_details">
                      <h2>Stuard Black</h2>
                      <h4>Financial Advisor</h4>
                    </div>
                  </div>
                </GridItem> */}
              </GridContainer>
            </div>
          </div>
        </div>

        <div className="bottomSectionGroup">
          {/* FAQ Section */}
          <div className="faq_section" name="faq">
            <div className="container">
              <div className="section text-center">
                <GridContainer>
                  <GridItem
                    sm={12}
                    md={10}
                    lg={10}
                    className="m-auto"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h1 className="main_title main_title_center">
                      FAQ'S
                      <small className="d-block mt-4 text-white">
                        (Coming Soon)
                      </small>
                    </h1>
                    {/* <p className="titleText">Join the industry leaders to discuss where the markets are heading. We accept token payments.</p> */}
                    <div className="faq_setion_panel">
                      {/* {
                    allFaqData && allFaqData.length > 0 && allFaqData.map((item,key)=>{
                      return (
                    <Accordion expanded={expanded === `panel${key}`} onChange={handleChangeFaq(`panel${key}`)}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id={`panel${key}bh-header`}>
                        <h2 className="accordian_head">{item.question}</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">{item.answer}</p>
                      </AccordionDetails>
                    </Accordion>
                      )
                    })
                  } */}
                    </div>
                    {/* <div className="faq_setion_panel">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <h2 className="accordian_head">What is Pankuku Token?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                        <h2 className="accordian_head">How to buy this ICO? </h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                        <h2 className="accordian_head">What cryptocoins supports? </h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                        <h2 className="accordian_head">How to transfer the tokens?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel5bh-header">
                        <h2 className="accordian_head">Can I transfer the token after puchase?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>
                  </div> */}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>

          <FooterHome />
        </div>
      </div>
    </div>
  );
}
